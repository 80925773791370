import { Controller } from 'stimulus'
import Swiper, { Navigation, EffectFade } from 'swiper' // Import EffectFade

Swiper.use([Navigation, EffectFade]) // Use EffectFade

export default class extends Controller {

  static targets = ['traySlider', 'contentSlider'];

  connect() {
    this.initMenu()
    window.addEventListener('load', this.updateMenuContentHeight.bind(this))
  }

  initMenu() {
        // Create menuTray Swiper instance
    const menuTraySelector = '.js-menuTray'
    const menuTrayElement = this.element.querySelector(menuTraySelector)
    const menuTraySlidesToShow = parseInt(menuTrayElement.dataset.slidestoshow) || 'auto'

    if (menuTrayElement) {
      this.menuTray = new Swiper(this.traySliderTarget, {
        slidesPerView: 3,
        slidesPerGroup: 1,
        slidesOffsetAfter: 0,
        centeredSlides: true,
        initialSlide: 1,
        threshold: 35,
        resistance: true,
        resistanceRatio: 0.85,
        breakpoints: {
          700: {
            slidesPerView: menuTraySlidesToShow,
            freeMode: true,
            spaceBetween: 0,
            centeredSlides: true,
            centeredSlidesBounds: true,
            threshold: 70,
            initialSlide: 0,
          },
          1280: {
            slidesPerView: menuTraySlidesToShow,
            freeMode: true,
            spaceBetween: 0,
            centeredSlides: false,
            allowTouchMove: false,
            threshold: 128,
          }
        }
      })
      this.menuTray.updateSlidesClasses()
    }

    // Create menuContent Swiper instance
    const menuContentSelector = '.js-menuContent'
    const menuContentElement = this.element.querySelector(menuContentSelector)

    if (menuContentElement) {
      this.menuContent = new Swiper(this.contentSliderTarget, {
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        autoHeight: true,
        navigation: false,
        freeMode: false,
        centeredSlides: false,
        threshold: 35,
        resistance: true,
        resistanceRatio: 0.85,
        spaceBetween: 0,
        speed: 600,
        allowTouchMove: false,
        breakpoints: {
          700: {
            threshold: 70,
            centeredSlides: true,
          },
        }
      })
    }

    // Make sure both instances exist before linking
    if (this.menuTray && this.menuContent) {
      // When the active slide of menuTray changes, update the active slide of menuContent
      this.menuTray.on('slideChange', () => {
        this.menuContent.slideTo(this.menuTray.activeIndex);
        this.menuTray.updateSlidesClasses();
      })
    }
  }

  slideTo(e) {
    let index = e.currentTarget.dataset.index
    this.menuContent.slideTo(index);
    this.menuTray.activeIndex = index;
    if (window?.innerWidth < 700) {
      this.menuTray.slideTo(index);
    }
    this.menuTray.updateSlidesClasses()
  }

  /**
   * Workaround for autoHeight not working on some edge cases
   * - initial slide has significantly less content than at least one other slide
   * - the height of the largest slide is at least 150% of the viewport height
   * - the page loads with the menu in view, and the above conditions are met
   */
  updateMenuContentHeight() {
    this.menuContent?.updateAutoHeight()
  }
}
